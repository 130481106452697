var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('sygni-card', {
    staticClass: "authorize-data-card"
  }, [_c('img', {
    staticClass: "authorize-data-card__icon",
    attrs: {
      "src": require("@/assets/icons/person.svg")
    }
  }), _c('div', {
    staticClass: "card__group"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v(" Authorize your data ")]), _c('div', {
    staticClass: "description"
  }, [_vm._v(" If you wish to view current VC fund listings for investment, please check your details and authorise them before proceeding further. This is necessary for compliance purposes. ")])]), _c('GenproxButton', {
    staticClass: "gn-primary filled",
    on: {
      "click": function click($event) {
        return _vm.emitAuthorizeDataClick();
      }
    }
  }, [_vm._v(" Authorize your data ")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }